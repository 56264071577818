<template>
<div class="maintenance-container">
    <h1>Lavori in Corso</h1>
    <p>Siamo spiacenti, ma il sito è attualmente in manutenzione. Torna a trovarci più tardi.</p>
</div>
</template>

<script>
export default {
name: 'LavoriInCorso'
}
</script>

<style scoped>
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: center;
  flex-direction: column;
}


</style>